/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: filththemutt (https://sketchfab.com/filththemutt)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/poster-basic-low-poly-eecc7216ab704bdf9c7fb1ef33c23889
title: Poster (Basic & Low Poly)
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import * as THREE from 'three'

export default function ModelPoster(props) {
  const { nodes, materials } = useGLTF("/poster_basic__low_poly.glb");
  const imageMap = props.image
  imageMap.wrapS = THREE.RepeatWrapping
  imageMap.repeat.set(1,1)
  //imageMap.rotation = Math.PI / 0.4
  return (
    <group {...props} dispose={null}>
      <group name="Sketchfab_Scene">
        <group name="Sketchfab_model" rotation={[0, 0, -Math.PI / 2]}>
          <group
            name="da03915a871f41d8bf8134affa9a6448fbx"
            rotation={[Math.PI / 2, 0, 0]}
            scale={0.01}
          >
            <group name="RootNode">
              <group name="Paper" rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                <mesh
                  name="Paper_Paper_Material_0"
                  castShadow
                  receiveShadow
                  geometry={nodes.Paper_Paper_Material_0.geometry}
                  material={materials.Paper_Material}
                >
                  <mesh
                  geometry={nodes.Paper_Paper_Material_0.geometry}
                  name="paper_image_hilder"
                  >
                    <meshStandardMaterial map={imageMap} color={'#ffffff'} metalness={1} roughness={0.7} envMapIntensity={0} opacity={1} transparent/>
                  </mesh>
                </mesh>
              </group>
              <group
                name="Tape_L"
                position={[-146.35, 0.75, 110]}
                rotation={[-Math.PI / 2, 0, -0.78]}
                scale={[31.5, 8.47, 100]}
              >
                <mesh
                  name="Tape_L_Material001_0"
                  castShadow
                  receiveShadow
                  geometry={nodes.Tape_L_Material001_0.geometry}
                  material={materials["Material.001"]}
                />
              </group>
              <group
                name="Tape_R"
                position={[-147.9, 0.75, -105.38]}
                rotation={[-Math.PI / 2, 0, -2.18]}
                scale={[31.5, 8.47, 100]}
              >
                <mesh
                  name="Tape_R_Material002_0"
                  castShadow
                  receiveShadow
                  geometry={nodes.Tape_R_Material002_0.geometry}
                  material={materials["Material.002"]}
                />
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/poster_basic__low_poly.glb");