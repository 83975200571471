import { createRoot } from 'react-dom/client'
import './styles.css'
import { App } from './App'

const pexel = (id) => `https://images.pexels.com/photos/${id}/pexels-photo-${id}.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260`
const imagesSrc = [
  {url: '/images/image2.jpg',title: '10 Photographers Who Captured the Romance of Paris, from Brassaï to Cartier-Bresson', text: 'There is an undeniable romanticism surrounding France’s capital city as it inhabits the collective consciousness—from the centuries-old buildings worn by time, to the expat-filled avant-garde circles of the ’20s, to the colorful characters in the city’s seedier districts. Over the decades, the spirit of Paris and its people has served as subject for some of the most influential photographers, who have in turn immortalized the city through their images. Here, we celebrate 10 of those photographers and the city that inspired them.', style: '4'},
  {url: '/images/image1.jpg',title: 'TITLE', text: 'bla bla bla', style: '1'},
  {url: '/images/image2.jpg',title: 'TITLE', text: 'bla bla bla', style: '1'},
  {url: '/images/image2.jpg',title: 'Eugène Atget, In his own words, Jean Eugène Auguste Atget created “documents for artists,”', text: 'images intended as source material for other creatives. Shot heavily in working-class areas, his photographs from the late 19th and early 20th centuries are carefully composed records of Old Paris’s shops, people, and historic architecture—unmanipulated scenes from Parisian daily life that bucked the trend of the then-fashionable, staged style of Pictorialism. Branching into the disorienting and the uncanny, his later work shares affinities with Surrealism, and was popularized by Man Ray and Berenice Abbott.', style: '4'},
  {url: pexel('15792209'),title: 'TITLE', text: 'bla bla bla', style: '1'},
  {url: pexel('6236640'),title: 'TITLE', text: 'bla bla bla', style: '1'},
  {url: '/images/image3.png',title: 'TITLE', text: 'bla bla bla', style: '3'},
  //{url: pexel('13015752'),title: 'TITLE', text: 'bla bla bla', style: '1'},
  {url: '/images/image2.jpg',title: 'Brassaï, Born Gyula Halász in the Hungarian town of Brassó, Brassaï moved to Paris in 1924', text: 'and befriended the city’s cultural intelligentsia, including Henry Miller and Pablo Picasso. Called “The Eye of Paris” by Miller, Brassaï is known for penetrating the city’s underbelly and capturing the people living at its margins. With unflinching directness and photographing mainly at night, he immortalized the prostitutes, artists, and petty criminals of Paris’s seamy Montparnasse district, and turned graffiti and weathered carpentry into considered studies of form.', style: '4'},
  {url: '/images/image4.jpg',title: 'TITLE', text: 'bla bla bla', style: '1'},
  //{url: pexel('15186487'),title: 'TITLE', text: 'bla bla bla', style: '1'},
  {url: '/images/image5.jpg',title: 'TITLE', text: 'bla bla bla', style: '1'},
  //{url: pexel('8989451'),title: 'TITLE', text: 'bla bla bla', style: '1'},
  //{url: pexel('10536106'),title: 'TITLE', text: 'bla bla bla', style: '1'},
  {url: '/images/image2.jpg',title: 'Jacques Henri Lartigue, began photographing as a child, capturing the games he played with', text: ' his brother and friends and injecting his own vision and personality into the instant, or action-based, photography that was popular at the time. Naturally gifted, Lartigue was an amateur photographer who considered himself primarily a painter. As such, his photographs are characterized by humorous informality, depicting fashionable women and the city’s denizens engaged in leisure activities like kite-flying, skiing, and car-racing, and showcasing the freedoms enjoyed by people of a certain class during the ’20s.', style: '4'},
  {url: '/images/image6.jpg',title: 'TITLE', text: 'bla bla bla', style: '1'},
  {url: pexel('15253317'),title: 'TITLE', text: 'bla bla bla', style: '1'},
  //{url: pexel('15436335'),title: 'TITLE', text: 'bla bla bla', style: '1'}
]
//
//

//
//

//
/*
const imagesSrc = [
  {url: '/images/image1.jpg'},
  {url: '/images/image2.jpg'},
  {url: pexel('15792209')},
  {url: pexel('6236640')},
  {url: '/images/image3.jpg'},
  {url: pexel('13015752')},
  {url: '/images/image4.jpg'},
  {url: pexel('15186487')},
  {url: '/images/image5.jpg'},
  {url: pexel('8989451')},
  {url: pexel('10536106')},
  {url: '/images/image6.jpg'},
  {url: pexel('15253317')},
  {url: pexel('15436335')}
]
*/
console.log(imagesSrc)
const images = [
  // Front
  { position: [0, 0, 1.5], rotation: [0, 0, 0], url: imagesSrc[0] },
  // Back
  { position: [-0.8, 0, -0.6], rotation: [0, 0, 0], url: imagesSrc[1] },
  { position: [0.8, 0, -0.6], rotation: [0, 0, 0], url: imagesSrc[2] },
  // Left
  { position: [-1.75, 0, 0.25], rotation: [0, Math.PI / 2.5, 0], url: imagesSrc[3] },
  { position: [-2.15, 0, 1.5], rotation: [0, Math.PI / 2.5, 0], url: imagesSrc[4] },
  { position: [-2, 0, 2.75], rotation: [0, Math.PI / 2.5, 0], url: imagesSrc[5] },
  // Right
  { position: [1.75, 0, 0.25], rotation: [0, -Math.PI / 2.5, 0], url: imagesSrc[4] },
  { position: [2.15, 0, 1.5], rotation: [0, -Math.PI / 2.5, 0], url: pexel(911738) },
  { position: [2, 0, 2.75], rotation: [0, -Math.PI / 2.5, 0], url: pexel(1738986) }
]
function askForPermession() {

}
createRoot(document.getElementById('root')).render(<App images={imagesSrc} />)
