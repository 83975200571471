/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: johnbudenberg (https://sketchfab.com/johnbudenberg)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/picture-frame-93b56a66331a4490b71a17a158b352a7
title: Picture Frame
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model(props) {
  const { nodes, materials } = useGLTF("/picture_frame.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Object_4.geometry}
              material={materials["3D_Oak_-_Semigloss.001"]}
            />
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/picture_frame.glb");
