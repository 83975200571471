import * as THREE from 'three'
import { memo } from 'react'
import { useEffect, useRef, useState } from 'react'
import React, { Suspense } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useCursor, MeshReflectorMaterial, Image, Text, Environment, ContactShadows, OrbitControls, CameraShake, SpotLight, Html , CameraControls, PerformanceMonitor, AccumulativeShadows, RandomizedLight, Lightformer, Sky, useHelper, useTexture, Plane} from '@react-three/drei'
import { useRoute, useLocation } from 'wouter'
import { easing, three } from 'maath'
import getUuid from 'uuid-by-string'
import { Flex, Box } from '@react-three/flex'
import Model from './Model'
import ModelPoster from './ModelPoster'
import { useControls } from 'leva'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper';
import Scroll from "./Scroll";

//<directionalLight position={[-5, 5, 5]} intensity={0.1} shadow-mapSize={128} castShadow />
//<directionalLight position={[-5, 5, -5]} intensity={0.1} shadow-mapSize={128} castShadow />
//<directionalLight position={[0, 5, 0]} intensity={0.1} shadow-mapSize={128} castShadow />
export const Env = () => {
    /*const { temporal, radius, ambient, color, positionX, positionY, positionZ } = useControls({
        temporal: { value: true },
        color: { value: '#797979' },
        radius: { value: 3, min: 0, max: 10 },
        ambient: { value: 0.6, min: 0, max: 1 },
        positionX: { value: -3.7, min: -15, max: 15 },
        positionY: { value: -0.7, min: -15, max: 15 },
        positionZ: { value: 2.3, min: -15, max: 15 },
      })*/

return (
  <>
    <AccumulativeShadows
        resolution={400}
        frames={100}
        temporal={true}
        color={'#797979'}
        colorBlend={1}
        alphaTest={0.8}
        opacity={1.65}
        scale={[60,39,1]}
        position={[-3.7,-0.7,2.3]}>
        <RandomizedLight radius={4} ambient={0.5} position={[2.9, 5, 11]} bias={0.001} size={60} mapSize={1000} />
      </AccumulativeShadows>
  </>
)}

function MLight() {
  const PL = useRef()
  //useHelper(PL, THREE.SpotLightHelper, 'red')
  //useHelper(PL, THREE.DirectionalLightHelper,1, 'red')
  return (
    <>
      
     <directionalLight ref={PL} position={[0, 2, 3]} intensity={1} shadow-mapSize={2000} shadow-camera-right={100} castShadow />
        
    </>
  )
}

function Wall() {
  const terrainTextures = useTexture({
    map: "/textures/concrete_wall_008_diff_1k.jpeg",
    displacementMap: "/textures/concrete_wall_008_disp_1k.jpg",
    aoMap: "/textures/concrete_wall_008_ao_1k.jpeg",
    roughnessMap: "/textures/concrete_wall_008_rough_1k.jpeg",
    //metalnessMap: "/textures/aerial_rocks_02_arm_4k_metalness_edited.jpg",
    normalMap: "/textures/concrete_wall_008_nor_gl_1k.jpg",
  });
  terrainTextures.map = useTexture("/textures/concrete_wall_008_diff_1k.jpeg", (texture) => {
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping
    texture.repeat.set(3 * (35 / 10), 3)
  })
  terrainTextures.displacementMap = useTexture("/textures/concrete_wall_008_disp_1k.jpg", (texture) => {
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping
    texture.repeat.set(3 * (35 / 10), 3)
  })
  terrainTextures.aoMap = useTexture("/textures/concrete_wall_008_ao_1k.jpeg", (texture) => {
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping
    texture.repeat.set(3 * (35 / 10), 3)
  })
  terrainTextures.roughnessMap = useTexture("/textures/concrete_wall_008_rough_1k.jpeg", (texture) => {
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping
    texture.repeat.set(3 * (35 / 10), 3)
  })
  terrainTextures.normalMap = useTexture("/textures/concrete_wall_008_nor_gl_1k.jpg", (texture) => {
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping
    texture.repeat.set(3 * (35 / 10), 3)
  })
  const defaultDisplacementScale = -0.2
  const defaultAoMapIntensity = 6.4
  const defaultRoughness = 0.77
  const defaultMetalness = 0.4
  const defaultNormalScale = [1, 1]
  /*const {
    displacementScale,
    aoMapIntensity,
    roughness,
    metalness,
    normalScale,
  } = useControls({
    displacementScale: {
      value: defaultDisplacementScale,
      min: -2,
      max: 2,
    },
    aoMapIntensity: {
      value: defaultAoMapIntensity,
      min: 0,
      max: 10,
    },
    roughness: {
      value: defaultRoughness,
      min: 0,
      max: 1,
    },
    metalness: {
      value: defaultMetalness,
      min: 0,
      max: 1,
    },
    normalScale: defaultNormalScale,
  });*/

  const mesh = useRef();
  /*useEffect(() => {
    mesh.current.geometry.setAttribute(
      "uv2",
      new BufferAttribute(mesh.current.geometry.attributes.uv.array, 2)
    );
  });*/
//<meshStandardMaterial map={texture} color={'#ffffff'} metalness={1} roughness={0.7} envMapIntensity={0} opacity={1}/>
  return (
    <Plane args={[60, 8, 2, 2]} receiveShadow position={[27, 0, -0.1]} ref={mesh}>
      <meshStandardMaterial
        {...terrainTextures}
        //normalMap-encoding={THREE.LinearEncoding}
        transparent
        displacementScale={defaultDisplacementScale}
        aoMapIntensity={defaultAoMapIntensity}
        roughness={defaultRoughness}
        metalness={defaultMetalness}
        envMapIntensity={0.5}
        metalnessMap={null}
        normalScale={new THREE.Vector2(defaultNormalScale[0], defaultNormalScale[1])}
      />
    </Plane>
  );
}
const GOLDENRATIO = 1.61803398875
const DEG45 = Math.PI / 4;
const imagesArray = []

export const App = ({ images }) => {
    const cameraControlRef = useRef(null);
    const cameraOrbitControlRef = useRef(null);
    const ref = useRef()
    const framesRef = useRef()
    const [lerping, setLerping] = useState(false)
    const [currentT, setCurrentT] = useState(-1)
    const [isCamFree, setCamFree] = useState(false)
    const [{ xAcceleration, yAcceleration, zAcceleration }, setMotion] = useState({ x: 0, y: 0, z:0 });
    const [{ camx, camy, camz }, setCamPos] = useState({ x: 0, y: 0, z:0 });
    const [{ alpha, beta, gamma }, setOrientation] = useState({ alpha: 0, beta: 0, gamma:0 });

    const [dpr, setDpr] = useState(2)

    //const [y] = Scroll([-100, 100], { domTarget: window });
    const [vrEnabled, setVrEnabled] = useState(false)
    const handleMotionEvent = event => {
      setMotion({
        xAcceleration: event.acceleration.x.toFixed(1),//event.acceleration.x,
        yAcceleration: event.acceleration.y.toFixed(1),//Math.ceil(event.acceleration.y),//event.acceleration.y,
        zAcceleration: event.acceleration.z.toFixed(1)//Math.ceil(event.acceleration.z),
      })
  }
  const handleOrientationEvent = event => {
    console.log(event.beta)
    setOrientation({
      alpha: event.alpha,//event.acceleration.x,
      beta: event.beta,//Math.ceil(event.acceleration.y),//event.acceleration.y,
      gamma: event.gamma//Math.ceil(event.acceleration.z),
    })
}

  /*useEffect(() => {
    const c = imagesArray[0]?.current?.children[currentT]
    //if (c && Math.abs(zAcceleration) >= 0.5) {
    if (c && isCamFree && Math.abs(zAcceleration / 4) >= 0.2) {
        const p = new THREE.Vector3()
        const q = new THREE.Quaternion()
        const zz = -(zAcceleration / 20)
        //beta
        cameraControlRef.current?.setFocalOffset(0, zz, 0, true)
        //cameraControlRef.current?.setTarget(camx, camy + zz, camz, true);//.then(() => cameraControlRef.current?.fitToBox(c, true, { cover: false, paddingTop : 0, paddingLeft: 0, paddingBottom : 0, paddingRight:0 }))
        //cameraControlRef.current?.truck(  0,  zz, true).then(() => cameraControlRef.current?.fitToBox(c, true, { cover: false, paddingTop : 0, paddingLeft: 0, paddingBottom : 0, paddingRight:0 }))
    }
}, [zAcceleration]);*/
/*
useEffect(() => {
    const c = imagesArray[0]?.current?.children[currentT]
    //if (c && Math.abs(zAcceleration) >= 0.5) {
    if (c && isCamFree && Math.abs(zAcceleration) >= 0.5) {
        const p = new THREE.Vector3()
        const q = new THREE.Quaternion()
        const zz = -(zAcceleration * 20)
        //beta
        cameraControlRef.current?.rotate(0, zz * THREE.MathUtils.DEG2RAD, true )
        //cameraControlRef.current?.setFocalOffset(zz, 0, 0, true)
        //cameraControlRef.current?.setTarget(camx, camy + zz, camz, true);//.then(() => cameraControlRef.current?.fitToBox(c, true, { cover: false, paddingTop : 0, paddingLeft: 0, paddingBottom : 0, paddingRight:0 }))
        //cameraControlRef.current?.truck(  0,  zz, true).then(() => cameraControlRef.current?.fitToBox(c, true, { cover: false, paddingTop : 0, paddingLeft: 0, paddingBottom : 0, paddingRight:0 }))
    }
}, [zAcceleration]);*/

/*useEffect(() => {
  const c = imagesArray[0]?.current?.children[currentT]
  //if (c && Math.abs(zAcceleration) >= 0.5) {
    const conf = 0.3;
  if (c && isCamFree && (Math.abs(zAcceleration) >= conf || Math.abs(xAcceleration) >= conf || Math.abs(yAcceleration) >= conf)) {
      const p = new THREE.Vector3()
      const q = new THREE.Quaternion()
      const zz =  Math.abs(gamma) >= conf ? gamma >= 0 ? -1 : 1 : 0
      const zb = Math.abs(zAcceleration) >= conf ? zAcceleration >= 0 ? 1 : -1 : 0
      //alert(zz)
      //beta
      //zz * THREE.MathUtils.DEG2RAD
      //cameraControlRef.current?.rotate(zz * 2 * THREE.MathUtils.DEG2RAD, (zb /2) * THREE.MathUtils.DEG2RAD, true )
      cameraControlRef.current?.rotate(zz * 2 * THREE.MathUtils.DEG2RAD, 0, true )
      //cameraControlRef.current?.setFocalOffset(zz, 0, 0, true)
      //cameraControlRef.current?.setTarget(camx, camy + zz, camz, true);//.then(() => cameraControlRef.current?.fitToBox(c, true, { cover: false, paddingTop : 0, paddingLeft: 0, paddingBottom : 0, paddingRight:0 }))
      //cameraControlRef.current?.truck(  0,  zz, true).then(() => cameraControlRef.current?.fitToBox(c, true, { cover: false, paddingTop : 0, paddingLeft: 0, paddingBottom : 0, paddingRight:0 }))
  }
}, [zAcceleration, yAcceleration, xAcceleration]);*/
      let currentTT = {current : -1};
      function moveLeft(f) {
        const calc = (currentTT.current + f)
        if (calc <= 0) {
          setCurrentTT(0)
        } else if (calc >= imagesArray[0].current.children.length) {
          setCurrentTT(imagesArray[0].current.children.length - 1)
        } else {
          setCurrentTT(calc)
        }
    }

    useEffect(() => {
      const c = imagesArray[0]?.current?.children[currentT]
      if (c) {
            cameraControlRef.current?.fitToBox(c, true, { cover: false, paddingTop : 0, paddingLeft: 0, paddingBottom : 0, paddingRight:0 }).then(() => {
              cameraControlRef.current?.fitToBox(c, true)
            })
      }
    }, [currentT]);

    function setCurrentTT(cur) {
      //console.log(cameraControlRef.current?.toJSON())
      currentTT.current = cur
      console.log(currentTT.current)
      const c = imagesArray[0]?.current?.children[currentTT.current]
        if (c) {
          //setCamFree(false)
            //cameraControlRef.current?.dolly(-0.4, true).then(() => cameraControlRef.current?.fitToBox(c, true));
            cameraControlRef.current?.fitToBox(c, true/*, { cover: false, paddingTop : 0, paddingLeft: 0, paddingBottom : 0, paddingRight:0 }*/).then(() => {
              cameraControlRef.current?.fitToBox(c, true)
              //setCurrentShadow(cur)
              //const lp = new THREE.Vector3()
              //cameraControlRef.current.getTarget(lp)
              //cameraControlRef.current.setTarget(c.)
              /*setCamPos({
                camx: lp.x,
                camy: lp.y,
                camz: lp.z
              })*/
              //setCamFree(true)
            })
        }
    }

    function resetShadows() {

    }

    function setCurrentShadow(cur) {
      const length = imagesArray[1]?.current?.children.length
      const freq = 4
      for (let i = 0; i < length + 1; i++) {
        const c = imagesArray[1]?.current?.children[i]
        let castShadow = false;
        if (i < cur + freq && i >= cur || i > cur - freq && i <= cur ) {
          castShadow = true;
        }
        if (c && c.children[0].castShadow != castShadow) {
          c.children[0].castShadow = castShadow;
        }
        console.log(i + ' : ' + castShadow)
      }
    }

    function moveBack(cof) {
      cameraControlRef.current?.zoomTo(cof,  true)
  }

    function gotoAnnotation(meshT) {
      //setCamFree(false)
        //cameraControlRef.current?.fitToBox(meshT.current, true, { cover: false, paddingTop : 0, paddingLeft: 0, paddingBottom : 0, paddingRight:0 }).then(() => setCamFree(true));
    }
    const updateOrientation = () => {
      console.log('oria');
    }
    function askForPermession() {
      DeviceMotionEvent.requestPermission()
            .then( response => {
              setVrEnabled(false);
              //alert('event mounted :' + response)
            // (optional) Do something after API prompt dismissed.
            if ( response == "granted" ) {
              window.addEventListener('devicemotion', handleMotionEvent, true);
              window.addEventListener('deviceorientation', handleOrientationEvent, true);
              //alert('event mounted')
            }
        })
            .catch( error => {setVrEnabled(true); alert(error)} )
    }

  

return (
    <>
  <Canvas camera={{ fov: 25, position: [-8, 3, 14] }} dpr={[1, 1.5]} shadows>
    <color attach="background" args={["black", "black", "black"]} />
    {/** PerfMon will detect performance issues 
     * <PerformanceMonitor onDecline={() => degrade(false)} /> touches={{one: 32,three: 64,two: 512}}
     * <fog attach="fog" args={['#191920', 0, 24]} />
     * <SpotLight
      position={[spositionX,2.4,1.2]}
      penumbra={penumbra}
      attenuation={1}
      anglePower={0.06} 
    >
      </SpotLight>
    */}
    <ambientLight intensity={-0.5} />
    <MLight />
    <CameraControls
      nextFunction={() => {console.log('going to next'); moveLeft(1)}}
      prevFunction={() => {console.log('going to prev'); moveLeft(-1)}}
     ref={cameraControlRef} smoothTime={0.5} minDistance={7} maxDistance={12} maxSpeed={8} dollySpeed={0.4}
     minAzimuthAngle={-Math.PI / 6}
     maxAzimuthAngle={Math.PI / 6}
     minPolarAngle={Math.PI / 3}
     maxPolarAngle={Math.PI - Math.PI / 3}
     dampingFactor={0.05} draggingDampingFactor={0.25}/>
    <group position={[0, -0.5, - 0.5]}>
      <Frames ref={framesRef} images={images} gotoAnnotation={gotoAnnotation}/>
      <Wall />    
    </group>
    
    <Environment preset="apartment" blur={0.3}>
    <Lightformer
    form="circle" // circle | ring | rect (optional, default = rect)
    intensity={2} // power level (optional = 1)
    color="white" // (optional = white)
    scale={[1,1]} // Scale it any way you prefer (optional = [1, 1])
    target={[1, 0, 0]} // Target position (optional = undefined)
  />
        </Environment>
      
    {/*
    
    <OrbitControls ref={ref} minAzimuthAngle={-Math.PI / 7} maxAzimuthAngle={Math.PI / 7} minPolarAngle={Math.PI / 3} maxPolarAngle={Math.PI - Math.PI / 2} enablePan={true} enableRotate={true} enableDamping={false}/>
    <Animate controls={ref} lerping={lerping} to={to} target={target} />
    <CameraShake intensity={1} /><Env/>*/}
  </Canvas>
  <div className={'fixed top-0 left-0 text-xs text-white backdrop-blur-sm p-4 py-2 w-full backdrop-brightness-105 backdrop-opacity-70 border-b border-b-white'}>
    You're visiting my Insta.museum, <span className='font-bold'>Welcome</span>
  </div>

  <div className={'fixed bottom-2 right-2 text-xl p-2 w-auto'}>
    <div className={'text-xl font-bold uppercase text-black backdrop-blur-sm w-auto border-top-1 backdrop-brightness-110 flex justify-center items-center gap-2 p-2 rounded-full'}>
      <div className='rounded-full border border-transparent bg-black'>
      <button onClick={() => {moveLeft(-1)}} type="button" class="rounded-full px-5 py-3 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 min-w-[25%]">‹</button>
      {/*<button onClick={() => {moveBack(0.5)}} type="button" class="rounded-full border border-transparent bg-black px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">.</button>
      <button onClick={() => {moveBack(1)}} type="button" class="rounded-full border border-transparent bg-black px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">.</button>
  <button onClick={() => {moveBack(1.5)}} type="button" class="rounded-full border border-transparent bg-black px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">.</button>*/}
      <button onClick={() => {moveLeft(1)}} type="button" class="rounded-full px-5 py-3 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 min-w-[25%]">›</button>
      </div>
    </div>
  </div>
  </>
)}

function Frames({ images, q = new THREE.Quaternion(), p = new THREE.Vector3(), gotoAnnotation}) {
  const ref = useRef()
  //const spots = useRef()
  const clicked = useRef()
  const [, params] = useRoute('/item/:id')
  const [, setLocation] = useLocation()
  useEffect(() => {
    imagesArray.push(ref)
    //imagesArray.push(spots)
  }, [])
  //useFrame((state) => {
    //previous.copy(lerped.current)
    //lerped.current.lerp(mouse, 0.1)
    // Regress system when the mouse is moved
    //if (!previous.equals(lerped.current)) state.performance.regress()
    //console.log(state)
    //state.performance.regress()
    //state.setPixelRatio(1)
  //})
  /*useFrame((state, dt) => {
    easing.damp3(state.camera.position, p, 0.4, dt)
    easing.dampQ(state.camera.quaternion, q, 0.4, dt)
  })*/
  return (
    <group>
        <Flex ref={ref} justifyContent="left" alignItems="center" flexDirection="row">
      {images.map((props, id) => 
        <Box padding={1.5} key={id}>
            {props.url && props.style == '1' && <Frame key={props.url} gotoAnnotation={gotoAnnotation} showText={id % 2} {...props} />}
            {props.url && props.style == '2' && <FrameC key={props.url} gotoAnnotation={gotoAnnotation} showText={id % 2} {...props} />}
            {props.url && props.style == '3' && <FramePoster key={props.url} gotoAnnotation={gotoAnnotation} showText={id % 2} {...props} />}
            {props.style == '4' && <FrameText key={props} gotoAnnotation={gotoAnnotation} showText={id % 2} {...props} />}
        </Box>
        )}
        </Flex>
        <group>
        {images.map((item, id) => 
            <FrameSpot key={id} items={ref} item={id} castShadow={false} position={[1.5 * (id * 2), 3.5, 2.5 + 0.5]}/>
        )}
        </group>
    </group>
  )
}

function Frame({ showText, url, c = new THREE.Color(), gotoAnnotation, ...props }) {
  const image = useRef()
  const frame = useRef()
  const texture = useTexture(url)
  const g = useRef()
  const [, params] = useRoute('/item/:id')
  const [hovered, hover] = useState(false)
  const [rnd] = useState(() => Math.random())
  const name = getUuid(url)
  const isActive = params?.id === name
  useEffect(() => {
    //console.log(image.current.material.uniforms.map.value.source.data.height width);
    const ratio = texture.source.data.height / texture.source.data.width
    console.log(ratio)
    image.current.scale.set(0.8, 0.8 * ratio, 1);
  }, [])
  useCursor(hovered)
  return (
    <group ref={g} onClick={() => gotoAnnotation(g)}>
      <mesh
        name={name}
        //onPointerOver={(e) => (e.stopPropagation(), hover(true))}
        //onPointerOut={() => hover(false)}
        scale={[1.2, GOLDENRATIO, 0.05]}
        position={[0, 0, -0.11]}
        //{...props}>}
        castShadow
        >
        <boxGeometry />
        <meshStandardMaterial color="#171716" metalness={1.2} roughness={0.4} envMapIntensity={2} />
        {showText && <mesh ref={frame} raycast={() => null} scale={[0.9, 0.93, 0.9]} position={[0, 0, 0.2]}>
          <boxGeometry />
          <meshBasicMaterial toneMapped={false} fog={false} metalness={0} roughness={0} envMapIntensity={0}/>
        </mesh>}
        <mesh scale={[0.9, 0.93, 0.9]} position={[0, 0, 0.4]}>
            <boxGeometry />
            <meshPhysicalMaterial
            transmission={1}
            roughness={0}
            thickness={0}
            envMapIntensity={0.2}
            clearcoat={1.5}
            clearcoatRoughness={0}
          />
        </mesh>
      </mesh>
      {/* <Image ref={image} position={[0, 0, 0.04 ]} texture={texture}/> */}
      <mesh ref={image} position={[0, 0, -0.07 ]} scale={[1, 1, 1]}>
        <planeGeometry/>
        <meshStandardMaterial map={texture} color={'#ffffff'} metalness={1} roughness={0.7} envMapIntensity={0} opacity={1}/>
      </mesh>
      {/* scale={[0.82, GOLDENRATIO-0.62, 0]} **/}
      {/* <mesh scale={[0.6, 0.3, 1]} position={[-0.2, -1.1, -0.162 ]}> */}
      {
        false &&
        <mesh scale={[0.6, 0.3, 1]} position={[-0.2, -1.1, -0.16 ]}>
      <planeGeometry/>
        <meshStandardMaterial color={'#ffffff'} metalness={1} roughness={0.7} envMapIntensity={0} opacity={1}/>
      {/*<Text position={[-0.6, -0.86, 0 ]}   color="black" anchorX="left" anchorY="top"
      font="https://fonts.gstatic.com/s/trocchi/v6/qWcqB6WkuIDxDZLcPrxeuw.woff"
      letterSpacing={0}
      maxWidth={1.2}
      //sdfGlyphSize: Math.pow(2, state.sdfGlyphSize),
      fontSize={0.06}>Tara se balade !</Text>
      {/*<Text position={[-0.6, -0.94, 0 ]}   color="black" anchorX="left" anchorY="top"
      font="https://fonts.gstatic.com/s/notosans/v7/o-0IIpQlx3QUlC5A4PNr5TRG.woff"
      letterSpacing={-0.05}
      maxWidth={1.2}
      //sdfGlyphSize: Math.pow(2, state.sdfGlyphSize),
      fontSize={0.04}>Tara se balade
      tara aime les photos
  Tara rencontre des amis</Text>*/}
      </mesh>
      }
    </group>
  )
}

function FrameC({ showText, url, c = new THREE.Color(), gotoAnnotation, ...props }) {
  const image = useRef()
  const frame = useRef()
  const texture = useTexture(url)
  const g = useRef()
  const [, params] = useRoute('/item/:id')
  const [hovered, hover] = useState(false)
  const [rnd] = useState(() => Math.random())
  const name = getUuid(url)
  const isActive = params?.id === name
  useEffect(() => {
    //console.log(image.current.material.uniforms.map.value.source.data.height width);
    const ratio = texture.source.data.height / texture.source.data.width
    console.log(ratio)
    image.current.scale.set(0.8, 0.8 * ratio, 1);
  }, [])
  useCursor(hovered)
  return (
    <group ref={g} onClick={() => gotoAnnotation(g)}>
      <mesh
        name={name}
        //onPointerOver={(e) => (e.stopPropagation(), hover(true))}
        //onPointerOut={() => hover(false)}
        scale={[1.2, GOLDENRATIO, 0.05]}
        //position={[0, GOLDENRATIO / 2, 0]}
        //{...props}>}
        castShadow
        >
        <Model scale={[0.2 , 0.004 , 0.0053]} position={[0.53, -0.5, 0]} rotation={[0, -Math.PI / 2, 0]} metalness={0} roughness={0} envMapIntensity={0} />
        {showText && <mesh ref={frame} raycast={() => null} scale={[0.9, 0.93, 0.9]} position={[0, 0, 0.2]}>
          <boxGeometry />
          <meshBasicMaterial toneMapped={false} fog={false} metalness={0} roughness={0} envMapIntensity={0}/>
        </mesh>}
        <mesh scale={[0.9, 0.93, 0.9]} position={[0, 0, 0.8]}>
            <boxGeometry />
            <meshPhysicalMaterial
            transmission={1}
            roughness={0}
            thickness={0}
            envMapIntensity={0.2}
            clearcoat={1.5}
            clearcoatRoughness={0}
          />
        </mesh>
      </mesh>
      {/* <Image ref={image} position={[0, 0, 0.04 ]} texture={texture}/> */}
      <mesh ref={image} position={[0, 0, 0.04 ]} scale={[1, 1, 1]}>
        <planeGeometry/>
        <meshStandardMaterial map={texture} color={'#ffffff'} metalness={1} roughness={0.7} envMapIntensity={0} opacity={1}/>
      </mesh>
      {/* scale={[0.82, GOLDENRATIO-0.62, 0]} **/}
      {/* <mesh scale={[0.6, 0.3, 1]} position={[-0.2, -1.1, -0.162 ]}> */}
      {
        false &&
        <mesh scale={[0.6, 0.3, 1]} position={[-0.2, -1.1, -0.16 ]}>
      <planeGeometry/>
        <meshStandardMaterial color={'#ffffff'} metalness={1} roughness={0.7} envMapIntensity={0} opacity={1}/>
      {/*<Text position={[-0.6, -0.86, 0 ]}   color="black" anchorX="left" anchorY="top"
      font="https://fonts.gstatic.com/s/trocchi/v6/qWcqB6WkuIDxDZLcPrxeuw.woff"
      letterSpacing={0}
      maxWidth={1.2}
      //sdfGlyphSize: Math.pow(2, state.sdfGlyphSize),
      fontSize={0.06}>Tara se balade !</Text>
      {/*<Text position={[-0.6, -0.94, 0 ]}   color="black" anchorX="left" anchorY="top"
      font="https://fonts.gstatic.com/s/notosans/v7/o-0IIpQlx3QUlC5A4PNr5TRG.woff"
      letterSpacing={-0.05}
      maxWidth={1.2}
      //sdfGlyphSize: Math.pow(2, state.sdfGlyphSize),
      fontSize={0.04}>Tara se balade
      tara aime les photos
  Tara rencontre des amis</Text>*/}
  <Html>
    saz
  </Html>
      </mesh>
      }
    </group>
  )
}

function FramePoster({ showText, url, c = new THREE.Color(), gotoAnnotation, ...props }) {
  const image = useRef()
  const frame = useRef()
  const texture = useTexture(url)
  const g = useRef()
  const [, params] = useRoute('/item/:id')
  const [hovered, hover] = useState(false)
  const [rnd] = useState(() => Math.random())
  const name = getUuid(url)
  const isActive = params?.id === name
  useEffect(() => {
    //console.log(image.current.material.uniforms.map.value.source.data.height width);
    const ratio = texture.source.data.height / texture.source.data.width
    console.log(image.current.children[0].children[0].children[0].children[0].children[0].children[0].children[0].children[0].material.map)
    //image.current.children[0].children[0].children[0].children[0].children[0].children[0].children[0].children[0].material.map.repeat.set(2, 2)
    //image.current.children[0].children[0].children[0].children[0].children[0].children[0].children[0].children[0].zoomTo(0.5, 0.5 * ratio, 1);
  }, [])
  useCursor(hovered)
  return (
    <group ref={g} onClick={() => gotoAnnotation(g)}>
      <mesh
        name={name}
        //onPointerOver={(e) => (e.stopPropagation(), hover(true))}
        //onPointerOut={() => hover(false)}
        //position={[0, GOLDENRATIO / 2, 0]}
        position={[0, 0, -0.16]}
        //{...props}>}
        castShadow
        ref={image}
        scale={0.5}
        >
        <ModelPoster image={texture}/>
      </mesh>
    </group>
  )
}

function FrameText({ showText, c = new THREE.Color(), gotoAnnotation, ...props }) {
  const g = useRef()
  const [, params] = useRoute('/item/:id')
  const [hovered, hover] = useState(false)
  const [rnd] = useState(() => Math.random())
  useCursor(hovered)
  useEffect(() => {
    //console.log(image.current.material.uniforms.map.value.source.data.height width);
  
  }, [])
  return (
    <group ref={g} onClick={() => gotoAnnotation(g)}>
      <mesh
        name={name}
        //onPointerOver={(e) => (e.stopPropagation(), hover(true))}
        //onPointerOut={() => hover(false)}
        position={[0, 0, -0.16]}
        //{...props}>}
        scale={[1.2, GOLDENRATIO, 0.2]}
        castShadow
        >
          <boxGeometry />
        <meshStandardMaterial color="#00000" metalness={1.2} roughness={0.4} envMapIntensity={2} />
        <mesh position={[0, 0, 0.52]} scale={[1, 1 * (1.2 / GOLDENRATIO), 0]}>
        <Text color="black" anchorX="left" anchorY="top" position={[-0.32, 0.5, 0 ]} 
      font="/header.woff"
      letterSpacing={0}
      maxWidth={0.65}
      //sdfGlyphSize: Math.pow(2, state.sdfGlyphSize),
      fontSize={0.05}
      lineHeight={1.2}
      overflowWrap={"break-word"}>{props.title}</Text>
      <Text position={[-0.32, 0.24, 0 ]} color="black" anchorX="left" anchorY="top"
      font="/content.woff"
      //letterSpacing={-0.05}
      maxWidth={0.65}
      //sdfGlyphSize: Math.pow(2, state.sdfGlyphSize),
      fontSize={0.04}
      lineHeight={1.1}
      overflowWrap={"break-word"}>{props.text}</Text>
      </mesh>
      </mesh>
    </group>
  )
}

  function FrameSpot({item, items, castShadow, ...props}) {
    const light = useRef()
    let mounted = false;
    useFrame(() => {
      if(items.current?.children[item] && !mounted) {
        //console.log(items.current?.children[item])
        light.current.target.position.lerp(items.current?.children[item].position, 1)
        light.current.target.updateMatrixWorld()
        mounted = true;
      }
    })
    return (<SpotLight ref={light} castShadow={castShadow} penumbra={0.3} distance={10} angle={0.3} attenuation={1.5} anglePower={3} intensity={5} {...props} />);
  }